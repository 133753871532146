
import { Options, Vue } from "vue-class-component";

@Options({
  emits: ["onScrollRight"],
  props: {
    showToggleScroll: {
      default: false,
      type: Boolean
    },
    hideScroll: {
      default: true,
      type: Boolean
    },
    hideToggle: {
      default: false,
      type: Boolean
    },
    leftScrollIconStyle: {
      default: "",
      type: String
    },
    leftScrollIconClass: {
      default: "",
      type: String
    },
    rightScrollIconStyle: {
      default: "",
      type: String
    },
    rightScrollIconClass: {
      default: "",
      type: String
    },
    scrollPositionPerToggle: {
      default: 0,
      type: Number
    }
  }
})
export default class HorizontalScrollContainer extends Vue {
  props: any = this.$props;
  scrollPercent = 0;

  onScroll(e: any) {
    this.scrollPercent = e.target.scrollLeft
      ? Math.round(
          (e.target.scrollLeft /
            (e.target.scrollWidth - e.target.offsetWidth)) *
            100
        )
      : 0;
    if (this.scrollPercent === 100) {
      this.$emit("onScrollRight");
    }
  }
  onToggleSlide(e: any, direction: string) {
    if (this.props.scrollPositionPerToggle) {
      if (direction === "left") {
        e.horizontalScrollContainer.scroll({
          left:
            e.horizontalScrollContainer.scrollLeft +
            this.props.scrollPositionPerToggle,
          behavior: "smooth"
        });
      } else {
        e.horizontalScrollContainer.scroll({
          left:
            e.horizontalScrollContainer.scrollLeft -
            this.props.scrollPositionPerToggle,
          behavior: "smooth"
        });
      }
    } else {
      if (direction === "left") {
        if (
          e.horizontalScrollContainer.clientWidth ===
          e.horizontalScrollContainer.scrollWidth
        ) {
          this.$emit("onScrollRight");
        }
        e.horizontalScrollContainer.scroll({
          left:
            e.horizontalScrollContainer.scrollWidth -
            e.horizontalScrollContainer.offsetWidth,
          behavior: "smooth"
        });
      } else {
        e.horizontalScrollContainer.scroll({
          left: 0,
          behavior: "smooth"
        });
      }
    }
  }
}
